@import './variables';

body {
    margin: 0; font-family: "Roboto", "Arial", sans-serif;
    overflow-x: hidden;

    &.modal-open {
        overflow: hidden;
    }
}

a {
    color: black;
}

a,
button,
a:hover,
button:hover,
a:focus,
button:focus {
    outline: none;
}

h3, h4, h5, h6 {
    font-family: "Roboto Condensed", "Arial", sans-serif;
    font-weight: bold;
    margin: 0;
    color: $global-text;
}
h3 {
    font-size: 22px;
    @media all and (min-width: 992px) {
        font-size: 24px;
    }
}
h1 {
    font-family: "Roboto Condensed", "Arial", sans-serif;
    margin: 0;
    color: $global-text;
}
h2,
span {
    font-family: "Roboto", "Arial", sans-serif;
    margin: 0;
    letter-spacing: normal;
    line-height: 1.5;
    color: $global-text;
    font-size: 18px;
}

p {
  color: $grey2;
  margin: 0;
  margin-bottom: 0 !important;
}

h1 {
    span {
        font-size: inherit;
    }
}

ul {
    list-style: none;
    padding: 0;
    li {
        margin: 0 0 10px;
    }
}

button,
.btn {
    background: none;
    border: none;
    border-radius: 6px;
    padding: 10px 28px;
}

main,
footer,
header,
article,
section {
    position: relative;
}

button.rounded-lg {
    border-radius: .5em !important;
}

.btn:focus {
    box-shadow: none;
}
.fixed-top {
    z-index: 950;
}

.BysideWindow {
    * {
        font-family: 'Roboto';
    }
    .BysideBarTitle {
        font-family: 'Roboto Condensed';
    }
}

.flex-1 {
  flex:1;
}

app-home,
app-home-v2,
app-home-v3,
app-our-offer,
app-get-offer,
app-online-medical-assistance,
app-covid,
app-electronic-prescription,
app-healthy-life,
app-pediatrician-online,
app-signup-process,
app-allergology,
app-cardiology,
app-dermatology,
app-nutrition-dietetics,
app-paediatrics,
app-personal-training,
app-psychology,
app-sexology,
app-gynaecology,
app-health-programmes,
app-health-test,
app-faqs,
app-online-health,
app-not-found-page,
app-dental,
app-gyneacology-insurance,
app-peadiatrics-insurance,
app-ophthalmology,
app-sorteo,
app-hiring,
app-cookies,
app-legal,
app-index,
app-contact,
app-home-dkv,
app-online-health-v2,
app-pets,
app-vitaldent,
main {
    margin-top: 98px;
}

@media all and (min-width: 992px) {
    app-home,
    app-home-v2,
    app-home-v3,
    app-our-offer,
    app-get-offer,
    app-online-medical-assistance,
    app-covid,
    app-electronic-prescription,
    app-healthy-life,
    app-pediatrician-online,
    app-signup-process,
    app-allergology,
    app-cardiology,
    app-dermatology,
    app-nutrition-dietetics,
    app-paediatrics,
    app-personal-training,
    app-psychology,
    app-sexology,
    app-health-programmes,
    app-health-test,
    app-faqs,
    app-online-health,
    app-not-found-page,
    app-dental,
    app-gyneacology-insurance,
    app-peadiatrics-insurance,
    app-ophthalmology,
    app-sorteo,
    app-hiring,
    app-cookies,
    app-legal,
    app-index,
    app-contact,
    app-home-dkv,
    app-online-health-v2,
    app-pets,
    app-vitaldent,
    main {
        margin-top: 109px;
    }
}


#callMe2 {
    #webcareslotcontainer_1867 + #webcareslotcontainer_1867 {
        display: none;
    }
}
