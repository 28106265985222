$primary-main: #35348a;
$primary-main-dark: #211F58;
$primary-main-alternative: #2D538A;
$secondary-main: #1776b2;
$secondary-second-main: #11527c;
$global-text: #2e2e2e;
$timer-text: #444440;
$blue-text: #00a9ce;
$back-global: #f4f4f4;
$back-grey: #F9F9F9;
$back-footer: #35348a;
$back-footer-v2: #111183;
$blue-toast: #0088A5;
$blue-link: #0065f4;
$blue-button: #36348A;
$cookies-link: #1467eb;
$cookies-text: #646464;
$gray-3: #87898E;
$gray: #979797;
$red: #e30000;
$grey1: #2D2E2F;
$grey2: #5C5F62;
$grey3: #D8DBDF;
$grey4: #BFC3C8;
$grey6: #E9ECEF;
$yellow: #FFE500;
$white: white;

$blue: #1ea8c7
