// Custom Theming for Angular Material
@use '~@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$masmovil-doctor-go-primary: mat.define-palette(mat.$indigo-palette);
$masmovil-doctor-go-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$masmovil-doctor-go-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$masmovil-doctor-go-theme: mat.define-light-theme((
  color: (
    primary: $masmovil-doctor-go-primary,
    accent: $masmovil-doctor-go-accent,
    warn: $masmovil-doctor-go-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($masmovil-doctor-go-theme);

/* Global Styles */
@import './theme/resets';
@import './theme/variables';
@import './theme/icons';
@import './theme/utils';
@import './theme/spinner';

/* Mixins */
@import './theme/mixins/functions';
// html, body { height: 100%; }

@import '~animate.css/animate.min';
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


// if you'd like to use it without importing all of bootstrap it requires
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~ngx-toastr/toastr';
@import '~swiper/swiper-bundle';

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
