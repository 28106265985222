@for $i from 1 through 100 {
    .fs-#{$i} {
        font-size: #{$i}px !important;
    }
}

@for $i from 1 through 100 {
    .gap-#{$i} {
        gap: #{$i}px;
    }
}

@for $i from 1 through 100 {
    .order-#{$i} {
        order: #{$i};
    }
}

@for $i from 1 through 100 {
    .h-vh-#{$i} {
        height: #{$i}vh;
        overflow: scroll;
    }
    .max-h-vh-#{$i} {
        max-height: #{$i}vh;
        overflow: scroll;
    }
    .min-h-vh-#{$i} {
        min-height: #{$i}vh;
        overflow: scroll;
    }
    .w-vw-#{$i} {
        width: #{$i}vw;
    }
    .max-w-vw-#{$i} {
        max-width: #{$i}vw;
        overflow: scroll;
    }
    .min-w-vw-#{$i} {
        min-width: #{$i}vw;
        overflow: scroll;
    }
}
