@charset "UTF-8";

@font-face {
  font-family: "masmovil-icons-font-1";
  src:url("../assets/fonts/masmovil-icons-font-1.eot");
  src:url("../assets/fonts/masmovil-icons-font-1.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/masmovil-icons-font-1.woff") format("woff"),
    url("../assets/fonts/masmovil-icons-font-1.ttf") format("truetype"),
    url("../assets/fonts/masmovil-icons-font-1.svg#masmovil-icons-font-1") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "masmovil-icons-font-1" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "masmovil-icons-font-1" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-icn-arrow-down:before {
  content: "\61";
}
.icon-icn-arrow-left:before {
  content: "\62";
}
.icon-icn-arrow-right:before {
  content: "\63";
}
.icon-icn-arrow-up:before {
  content: "\64";
}
.icon-social-apple:before {
  content: "\65";
}
.icon-icn-facebook:before {
  content: "\66";
}
.icon-icn-twitter:before {
  content: "\67";
}
.icon-icn-youtube:before {
  content: "\68";
}
.icon-icn-devices:before {
  content: "\69";
}
.icon-icn-close:before {
  content: "\6b";
}
.icon-icn-check-energy:before {
  content: "\6a";
}
.icon-icn-play:before {
  content: "\6c";
}
