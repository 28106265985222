@import "../theme/variables";

.py-6 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.pt-6 {
  padding-top: 4rem;
}

.pb-6 {
  padding-bottom: 4rem;
}

.bwc_lateral {
  display: none;
}
.small-title {
  opacity: 0.7;
  font-size: 14px;
}
.smaller-title {
  opacity: 0.7;
  font-size: 10px;
}
.scrollOff {
  overflow-y: hidden;
}
.dropdown-item.active,
.dropdown-item:active {
  background: none !important;
}
a.active-link {
  display: inline-block;
  color: $blue-text !important;
}

a.dropdown-item.active-link-new {
  display: inline-block;
  color: white;
  background-color: $blue-text;

  &:active {
    color: white !important;
    background-color: initial;
  }

  &:hover {
    color: white !important;
  }
}


.active-link {
  // display: inline-block;
  // color: $blue-text !important;
  // border-bottom: 4px solid $blue-text;
  // position: relative;
  &::before {
    content: "";
    position: absolute;
    bottom: 2px;
    height: 4px;
    width: 100%;
    background-color: $blue-text;
  }
  &.nav-item,
  &.dropdown-item {
    &::before {
      display: none;
    }
  }
  .nav-link {
    display: inline-block;
    color: $blue-text !important;
    // border-bottom: 4px solid $blue-text;
    border: none;
    position: relative;
    &::before {
      display: none;
      // content: '';
      // position: absolute;
      // bottom: 0;
      // height: 4px;
      // width: 100%;
      // background-color: $blue-text;
    }
  }
  // .dropdown-menu {
  //     .active-link {
  //         color: $blue-text !important;
  //         font-weight: bold;
  //     }
  // }
}

.my-card {
  border-radius: 8px;
  box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.08);
  padding: 42px 23px 26px 23px;
  max-width: 311px;
  .text {
    font-weight: 500;
    a {
      text-decoration: underline;
    }
  }
}
// .my-card-no-bg {

// }
.text-blue-text {
  color: $blue-text;
}
.text-gray {
  color: $grey1;
}
.text-gray-3 {
  color: $gray-3;
}
.text-primary-text {
  color: $primary-main;
}
.bg-blue-button {
  background-color: $blue-button;
}
.text-test-it {
  color: $gray;
}
.text-stores-link {
  color: $blue-text;
}
.bg-stores {
  background-color: $blue-text !important;
}
.text-cookies-link {
  color: $cookies-link;
}
.text-cookies-text {
  color: $cookies-text;
}
.text-toast {
  color: $primary-main-alternative;
}
.bg-toast {
  background-color: $blue-toast;
}
.bg-cookies {
  background-color: $cookies-link;
}
.bg-blue-text {
  background-color: $blue-text;
}
.bg-primary-main-alternative {
  background-color: $primary-main-alternative;
}
.bg-border-black {
  border: 1px solid $grey1;
}
.text-global {
  color: $global-text !important;
}
.text-red {
  color: $red;
}

.bg-none {
  background: none !important;
}

.border-grey {
  border: 1px solid $grey3;
}

.icon-icn-play {
  &.reverse {
    transform: rotate(180deg);
  }
}

.weight-700 {
  font-weight: 700;
}
.inputError {
  color: #ce0c00;
  font-size: 12px;
  padding: 7px 7px 7px 15px;
  background-color: #feefef;
  border-radius: 6px;
  display: flex;
  align-items: flex-start;
  img {
    margin-right: 10px;
  }
  p {
    color: #ce0c00;
  }
  span {
    color: $grey1;
    font-size: 12px;
  }
  &.second {
    margin-top: -24px;
    margin-bottom: 16px;
  }
}

[class*="-page"],
[class*="-page"] > .section-banner {
  > header {
    background-color: $blue-text;
    color: #fff;
    font-size: 18px;
    &:not(.speciality) {
      padding-top: 24px;
    }
    > .container {
      padding-top: 24px;
      padding-bottom: 24px;
      position: relative;
    }
    h1 {
      position: relative;
      z-index: 10;
      font-size: 30px;
      font-stretch: condensed;
      font-weight: bolder;
      letter-spacing: normal;
      > span {
        display: inline-block;
        font-family: "Roboto Condensed";
        padding: 2px 5px;
        background-color: #fff;
        margin-bottom: 8px;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5);
        white-space: nowrap;
        line-height: 44px;
        span {
          font-family: "Roboto Condensed";
          line-height: 44px;
        }
      }
    }
    p {
      color: #fff;
      line-height: 1.49;
    }
  }
  > main {
    article {
      h3 {
        font-family: "Roboto";
      }
    }
  }
}
.faqs-page {
  > header {
    p {
      color: $global-text;
    }
  }
}
.bg-primary-main-real {
  background-color: $primary-main;
}
.bg-primary-main {
  background-color: $secondary-second-main;
}
.bg-black {
  background-color: #000;
}
.bg-primary-main-dark {
  background-color: $primary-main-dark;
}
#webcareslotcontainer_1029 {
  display: none !important;
}
#phco {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.check {
  position: relative;
  display: flex;
  align-items: center;
  &::before {
    content: "L";
    transform: scaleX(-1) rotate(-45deg);
    display: inline-block;
    margin-right: 10px;
    font-size: 14px;
    align-self: flex-start;
  }
}

.nowrap {
  white-space: nowrap;
}

#callMe2 {
  position: absolute;
  z-index: 100;
  min-width: 332px;
  top: 24px;
  right: -159px;
  display: none;
  @media screen and (min-width: 1440px) {
    display: block;
  }
}

#webcareslotcontainer_1867 {
  .BysideLogo {
    display: none;
  }
}

@media all and (min-width: 375px) {
  .fs-14.fs-sm-16 {
    font-size: 16px !important;
  }
}
@media all and (min-width: 414px) {
  [class*="-page"],
  [class*="-page"] > .section-banner {
    > header {
      h1 {
        font-size: 30px;
      }
    }
  }
}
// @media all and (min-width: 457px) {
//     [class*='-page'] {
//         > header {
//             h1 {
//                 // font-size: 30px;
//             }
//         }
//     }
// }

@media all and (min-width: 360px) {
  .container {
    padding-left: 16px;
    padding-right: 16px;
  }
}
@media all and (min-width: 640px) {
  [class*="-page"],
  [class*="-page"] > .section-banner {
    > header {
      h1 {
        font-size: 36px;
        > span {
          padding: 2px 8px;
        }
      }
    }
  }
  .container {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media all and (min-width: 768px) {
  [class*="-page"],
  [class*="-page"] > .section-banner {
    > header {
      padding-top: 0px !important;
      > .container {
        padding-top: 54px;
      }
      h1 {
        font-size: 36px;
        > span {
          padding: 2px 8px;
        }
      }
      .content-header {
        span {
          font-size: 25px !important;
        }
        p {
          font-size: 24px;
        }
      }
    }
  }
  .my-card {
    min-height: 319px;
  }
}

@media all and (min-width: 992px) {
  .container {
    max-width: 1200px;
  }

  [class*="-page"],
  [class*="-page"] > .section-banner {
    > header {
      h1 {
        font-size: 40px;
      }
    }
  }
}

@media all and (min-width: 1200px) {

  .container {
    max-width: 1600px !important;
  }

  [class*="-page"],
  [class*="-page"] > .section-banner {
    > header {
      h1 {
        font-size: 42px;
      }
    }
  }
}

.desktop {
  display: none !important;
  @media all and (min-width: 768px) {
    display: block !important;
  }
}
.mobile {
  display: block;
  @media all and (min-width: 768px) {
    display: none !important;
  }
}
.desktop-flex {
  display: none !important;
  @media all and (min-width: 768px) {
    display: flex !important;
  }
}
.mobile-flex {
  display: flex;
  @media all and (min-width: 768px) {
    display: none !important;
  }
}

/* CONTENT W */
@media screen and (min-width: 360px) {
  .content-w {
    max-width: 328px;
    margin: 0 auto;
  }
}
@media screen and (min-width: 640px) {
  .content-w {
    max-width: 576px !important;
    margin: 0 auto;
  }
}
@media screen and (min-width: 1080px) {
  .content-w {
    max-width: 1020px !important;
    margin: 0 auto;
  }
}
@media screen and (min-width: 1440px) {
  .content-w {
    max-width: 1020px !important;
    margin: 0 auto;
  }
}

.text-underline {
  text-decoration: underline;
}
